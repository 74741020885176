<template>
  <base-modal>
    <template #header>
      <h3 class="m-0 mb-2">Add your credit card</h3>
      <p class="p2">
        Setup your credit card details and begin your free trial. You won’t be charged until your trial expires.
      </p>
    </template>
    <template #default>
      <card-form
        :parent="parent"
        @cancel="$emit('close')"
        @submitted="submitted"
      >
        <template #action-btn="{ submit, formReady, creatingCard, close }">
          <div class="flex space-x-4 justify-center pt-4">
            <the-button
              @click="close"
              secondary
            >
              Cancel
            </the-button>

            <the-button
              @click="submit"
              :disabled="!formReady || creatingCard"
              primary
            >
              Save
            </the-button>
          </div>
        </template>
      </card-form>
    </template>
  </base-modal>
</template>

<script>
import CardForm from '@/components/account/CardForm'

export default {
  name: 'card-modal',
  components: { CardForm },
  props: {
    parent: {
      type: String,
      required: true,
    },
    onSubmitted: {
      type: Function,
      required: false,
    },
  },
  modalProps: {
    height: 'auto',
    maxWidth: 840,
    scrollable: true,
  },
  methods: {
    submitted() {
      if (!!this.onSubmitted) this.onSubmitted()
      this.$emit('close')
    },
  },
}
</script>
